import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";
import Cookies from "js-cookie";
import { isEmpty } from "lodash";

const initialState = {
    activeShops: false,
    userData: false,
    shopStat: false,
    userStat: false,
    statDay: false,
    statMonth: false,
    topUsers: false,
    topShops: false,
    loading: false,
};

const BASE_URL = 'https://monitor.gonec-eda.ru';


const getAuthData = async () => {
    let token = await Cookies.get("jwt");
    let config = {
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        }
    }
    return config;
};

export const getActiveShops = createAsyncThunk(
    'admin/getActiveShops', async (data, { rejectWithValue, dispatch }) => {
        let config = {
            headers: {
                'Content-Type': 'application/json'
            },
            auth: {
                username: 'ck_c09d4bf6e9dc9edc8948cece0e60213d2cda46a6',
                password: 'cs_e408463f458c84988ed2fd2393dcecbb893f8ed7'
            }
        }
        const res = await axios.get(`https://gonec-eda.ru/wp-json/wc/v3/stores`, config)
        dispatch(setActiveShops(res.data));
    }
);

export const getStatDay = createAsyncThunk(
    'admin/getStatDay', async (data, { rejectWithValue, dispatch }) => {
        try {
            let config = await getAuthData();
            const res = await axios.get(BASE_URL + `/api/v1/order/stat/day`, config)
            dispatch(setStatDay(res.data));
        }   catch (error) {
            const message = (error.response.data.message) || error.toString();
            return rejectWithValue(message);
        }  
    }
);

export const getStatMonth = createAsyncThunk(
    'admin/getStatMonth', async (data, { rejectWithValue, dispatch }) => {
        try {
            let config = await getAuthData();
            const res = await axios.get(BASE_URL + `/api/v1/order/stat/month`, config)
            dispatch(setStatMonth(res.data));
        }   catch (error) {
            const message = (error.response.data.message) || error.toString();
            return rejectWithValue(message);
        }  
    }
);

export const getUsers = createAsyncThunk(
    'admin/getUsers', async (data, { rejectWithValue, dispatch }) => {
        try {
            let config = await getAuthData();
            const res = await axios.get(BASE_URL + `/api/v1/users/couriers`, config)
            dispatch(setUserData(res.data));
        }   catch (error) {
            const message = (error.response.data.message) || error.toString();
            return rejectWithValue(message);
        }  
    }
);

export const getUserStat = createAsyncThunk(
    'admin/getUserStat', async (data, { rejectWithValue, dispatch }) => {
        try {
            let config = await getAuthData();
            const res = await axios.get(BASE_URL + `/api/v1/order/stat/user?userId=${data.userId}&date=${data.date}`, config)
            dispatch(setUserStat(res.data));
        }   catch (error) {
            const message = (error.response.data.message) || error.toString();
            return rejectWithValue(message);
        }  
    }
);

export const getShopStat = createAsyncThunk(
    'admin/getShopStat', async (data, { rejectWithValue, dispatch }) => {
        try {
            let config = await getAuthData();
            if (!isEmpty(data.endDate)) {
                var res = await axios.get(BASE_URL + `/api/v1/order/stat/store?store=${data.store}&date=${data.date}&endDate=${data.endDate}`, config)
            } else {
                var res = await axios.get(BASE_URL + `/api/v1/order/stat/store?store=${data.store}&date=${data.date}`, config)
            }
            // const res = await axios.get(BASE_URL + `/api/v1/order/stat/store?store=${data.store}&date=${data.date}`, config)
            dispatch(setShopStat(res.data));
        }   catch (error) {
            const message = (error.response.data.message) || error.toString();
            return rejectWithValue(message);
        }  
    }
);

export const getTopUsers = createAsyncThunk(
    'admin/getTopUsers', async (data, { rejectWithValue, dispatch }) => {
        try {
            let config = await getAuthData();
            const res = await axios.get(BASE_URL + `/api/v1/order/topusers/month`, config)
            dispatch(setTopUsers(res.data));
        }   catch (error) {
            const message = (error.response.data.message) || error.toString();
            return rejectWithValue(message);
        }  
    }
);

export const getTopShops = createAsyncThunk(
    'admin/getTopShops', async (data, { rejectWithValue, dispatch }) => {
        try {
            let config = await getAuthData();
            const res = await axios.get(BASE_URL + `/api/v1/order/topstores/month`, config)
            dispatch(setTopShops(res.data));
        }   catch (error) {
            const message = (error.response.data.message) || error.toString();
            return rejectWithValue(message);
        }  
    }
);

export const AdminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        setActiveShops: (state, action) => {
            state.activeShops = action.payload;
        },
        setUserData: (state, action) => {
            state.userData = action.payload;
        },
        setUserStat: (state, action) => {
            state.userStat = action.payload;
        },
        setShopStat: (state, action) => {
            state.shopStat = action.payload;
        },
        setStatDay: (state, action) => {
            state.statDay = action.payload;
        },
        setStatMonth: (state, action) => {
            state.statMonth = action.payload;
        },
        setTopUsers: (state, action) => {
            state.topUsers = action.payload;
        },
        setTopShops: (state, action) => {
            state.topShops = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                (action) => action.type.endsWith('/pending'),
                (state, action) => { state.loading = true; }
            )
            .addMatcher(
                (action) => action.type.endsWith('/fulfilled'),
                (state, action) => { state.loading = false; }
            )
            .addMatcher(
                (action) => action.type.endsWith('/rejected'),
                (state, action) => {
                    state.loading = false;
                }
            );
    }
});

export const { 
    setActiveShops, 
    setUserData, 
    setUserStat, 
    setShopStat,
    setStatDay,
    setStatMonth,
    setTopUsers,
    setTopShops
} = AdminSlice.actions

export default AdminSlice.reducer