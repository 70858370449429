import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isEmpty } from 'lodash';

import Sidebar from '../../components/Sidebar';
import Loader from '../../components/Loader';

import { setPushData, PushRequest, setError } from '../../features/MarketingSlice';

export default function OrdersPage() {

    const dispatch = useDispatch();
    const state = useSelector((state) => state.marketing);

    const [invalidTitle, setInvalidTitle] = useState(false);
    const [invalidText, setInvalidText] = useState(false);
    const [invalidPin, setInvalidPin] = useState(false);
    const [showPass, setShowPass] = useState(false);

    const pushNotification = (e) => {
        if (e) { e.preventDefault(); }
        dispatch(setError(false));
        if (!isEmpty(state.pushData.title)) {
            setInvalidTitle(false);
        } else {
            setInvalidTitle(true);
            return;
        }
        if (!isEmpty(state.pushData.text)) {
            setInvalidText(false);
        } else {
            setInvalidText(true);
            return;
        }
        if (!isEmpty(state.pushData.pin)) {
            setInvalidPin(false);
        } else {
            setInvalidPin(true);
            return;
        }
        if (state.pushData.test === false) {
            if (window.confirm(`Вы уверены, что хотите отправить ВСЕМ ПОЛЬЗОВАТЕЛЯМ уведомления?`)) {
                dispatch(PushRequest(state.pushData));
            }
        } else {
            dispatch(PushRequest(state.pushData));
        }
    };

    return (
        <>
            <Sidebar/>
            <main className="main-wrapper">
                <div className="main-content">
                    <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                        <div className="ps-3">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item active" aria-current="page">Отправка Push-Notifications</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    {state.loading === true ? (
                        <div className='col-12 text-center mt-5'>
                            <Loader/>
                        </div>
                    ) : (
                        <div className="row">
                            <div className="col-12 col-md-8 d-flex align-items-stretch">
                                <div className="card w-100 rounded-4">
                                    <div className="card-body m-3">
                                        {state.error && <h4 className="fw-bold text-danger">Вы ввели неправильный пин-код!</h4>}
                                        <h4 className="fw-bold">Push-Notifications</h4>
                                        <p className="mb-0">Заполните поля для отправки Push-Notifications</p>
                                        <div className="form-body mt-4">
                                            <form className="row g-3" onSubmit={pushNotification}>
                                                <div className="col-12">
                                                    {invalidTitle ? (
                                                        <label htmlFor="inputTitle" className="form-label text-danger">Заголовок не может быть пустым</label>
                                                    ) : (
                                                        <label htmlFor="inputTitle" className="form-label">Заголовок</label>
                                                    )}
                                                    <input 
                                                        type="text" 
                                                        name='title'
                                                        className={`form-control ${invalidTitle ? 'border-danger' : 'border-warning'}`}
                                                        id="inputTitle" 
                                                        placeholder="введите до 30 символов..."
                                                        onChange={(e) => dispatch(setPushData({key: e.target.name, val: e.target.value}))}
                                                    />
                                                </div>
                                                <div className="col-12">
                                                    {invalidText ? (
                                                        <label htmlFor="inputText" className="form-label text-danger">Текст не может быть пустым</label>
                                                    ) : (
                                                        <label htmlFor="inputText" className="form-label">Текст</label>
                                                    )}
                                                    <textarea 
                                                        type="text" 
                                                        name='text'
                                                        className={`form-control ${invalidText ? 'border-danger' : 'border-warning'}`}
                                                        id="inputText"   
                                                        rows={3}
                                                        placeholder="введите до 150 символов..."
                                                        onChange={(e) => dispatch(setPushData({key: e.target.name, val: e.target.value}))}
                                                    />
                                                </div>
                                                <div className="col-5">
                                                    {invalidPin ? (
                                                        <label htmlFor="inputPinCode" className="form-label text-danger">Введите PIN-код</label>
                                                    ) : (
                                                        <label htmlFor="inputPinCode" className="form-label">PIN-код</label>
                                                    )}
                                                    <div className="input-group">
                                                        <input 
                                                            type={showPass ? 'text' : 'password'}
                                                            name='pin'
                                                            className={`form-control ${invalidPin ? 'border-danger' : 'border-warning'}`}
                                                            id="inputPinCode"   
                                                            placeholder="****"
                                                            onChange={(e) => dispatch(setPushData({key: e.target.name, val: e.target.value}))}
                                                        />
                                                        <span className="input-group-text bg-transparent border-warning" onClick={() => setShowPass(!showPass)}>
                                                            {showPass === true ? (
                                                                <i className="bi bi-eye-fill text-warning"></i>
                                                            ) : (
                                                                <i className="bi bi-eye-slash-fill"></i>
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-7"></div>
                                                <div className="col-12">
                                                    <div className="form-check form-check-warning mt-3">
                                                        <input 
                                                            className="form-check-input" 
                                                            type="checkbox" 
                                                            id="test"
                                                            name='test'
                                                            onChange={(e) => dispatch(setPushData({key: e.target.name, val: e.target.checked}))}
                                                        />
                                                        <label className="form-check-label" htmlFor="test">Отправить тестовое уведомление</label>
                                                    </div>
                                                    <label htmlFor="inputPinCode" className="form-label" style={{fontSize: '12px'}}>
                                                        Push-notification придет только пользователям:
                                                        <br/>
                                                        <strong>Валерия и Кирилл</strong>
                                                    </label>
                                                </div>
                                                {state.loading === true ? (
                                                    <div className="col-4">
                                                        <div className="d-grid">
                                                            <Loader/>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    state.responce && state.responce.status === 'ok' ? (
                                                        <div className="col-12">
                                                            <div className="d-grid">
                                                                <div className="alert alert-success border-0 bg-grd-success alert-dismissible fade show">
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="font-35 text-white">
                                                                            <span className="material-icons-outlined fs-2">check_circle</span>
                                                                        </div>
                                                                        <div className="ms-3">
                                                                            <h6 className="mb-0 text-white">{state.responce.message}</h6>
                                                                            <div className="text-white">{state.responce.count}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="col-4">
                                                            <div className="d-grid">
                                                                <button type="submit" className="btn btn-warning mt-3 text-white">Отправить</button>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-4 d-flex align-items-stretch">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="alert alert-br alert-info border-0 bg-grd-info alert-dismissible fade show pe-1 pe-md-3">
                                            <div className="d-flex align-items-center">
                                                <div className="font-35 text-white d-none d-md-block">
                                                    <span className="material-icons-outlined fs-2">info</span>
                                                </div>
                                                <div className="ms-1 ms-md-3">
                                                    <div className="text-white">
                                                        <span style={{fontSize: '15px', fontWeight: '600'}}> Размер ваших push-уведомлений: </span>
                                                        <br/>
                                                        <br/>
                                                        <span style={{fontSize: '14px', fontWeight: '600'}}> Заголовок </span>
                                                        – максимум 30 символов (ограничение в 1 строку).
                                                        <br/>
                                                        <br/>
                                                        <span style={{fontSize: '14px', fontWeight: '600'}}> Текст </span>
                                                        – максимум 150 символов (ограничение в 3 строки).
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="alert alert-br alert-warning border-0 bg-grd-warning alert-dismissible fade show pe-1 pe-md-3">
                                            <div className="d-flex align-items-center">
                                                <div className="font-35 text-white d-none d-md-block">
                                                    <span className="material-icons-outlined fs-2">check_box</span>
                                                </div>
                                                <div className="ms-1 ms-md-3">
                                                    <div className="text-white">
                                                        <span style={{fontSize: '15px', fontWeight: '600'}}>Отправить тестовое уведомление</span>
                                                        <br/>
                                                        <br/>
                                                        – push-notification придет только пользователям:
                                                        <br/>
                                                        <br/>
                                                        Валерия и Кирилл, 
                                                        <br/>
                                                        <br/>чтобы вы могли протестировать свое уведомление!
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {state.error && 
                                            <div className="alert alert-br alert-danger border-0 bg-grd-danger alert-dismissible fade show pe-1 pe-md-3">
                                                <div className="d-flex align-items-center">
                                                    <div className="font-35 text-white d-none d-md-block">
                                                        <span className="material-icons-outlined fs-2">dangerous</span>
                                                    </div>
                                                    <div className="ms-1 ms-md-3">
                                                        <div className="text-white">
                                                            <span style={{fontSize: '16px', fontWeight: '600'}}>Ошибка!</span>
                                                            <br/>
                                                            <br/>
                                                            <span style={{fontSize: '15px', fontWeight: '600'}}>Вы ввели неправильный пин-код!</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </main>
        </>
    );
};